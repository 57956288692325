import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Avatar, Button, Card, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuthContext } from '../../../auth/useAuthContext';
import LoadingScreen from '../../../components/loading-screen';
import { getCabinet } from '../../../redux/slices/cabinet/cabinetThunk';
import { createCartePatient, createCommand, } from '../../../redux/slices/cartePatient/cartePatientThunk';
import { getOnePatient } from '../../../redux/slices/patient/patientThunk';
import { insertFiles, resetStep, setDents, setDescription, setProtheseId, setTreatment, setUpdate, setUpdateProthese, } from '../../../redux/slices/prothese/protheseSlice';
import { getProtheseById } from '../../../redux/slices/prothese/protheseThunk';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { formatDate } from '../../../utils/formatDate';
import { stringAvatar } from '../../../utils/stringAvatar';
import { getLaboratorie } from '../../../redux/slices/laboratorie/laboratorieThunk';
export default function PatientThirdStep({ setActiveStep, setSelectedDent, }) {
    const { id, treatmentId } = useParams();
    const date = new Date();
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!treatmentId)
            navigate(PATH_DASHBOARD.patientForm
                .replace(':step', '1')
                .replace(':id?', id ?? '')
                .replace(':treatmentId?', '') + `?lastStepCompleted=${1}`);
    }, [treatmentId]);
    useEffect(() => {
        if (treatmentId)
            dispatch(getProtheseById({ id: treatmentId }));
        if (id)
            dispatch(getOnePatient({ id }));
        dispatch(getCabinet({}));
        dispatch(getLaboratorie({}));
    }, []);
    const prothese = useSelector((state) => state.dent);
    const patient = useSelector((state) => state.patient);
    const cabinet = useSelector((state) => state.cabinet);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const handleChangeCabinet = (event) => {
        setSelectValue(event.target.value);
    };
    const [select, setSelectValue] = useState(undefined);
    const isDentist = user?.roles[0]?.label === 'DENTIST';
    useEffect(() => {
        if (cabinet?.data.length > 0)
            setSelectValue(cabinet?.data[0]?._id);
    }, [cabinet?.data]);
    return (_jsx(_Fragment, { children: prothese?.loading || patient?.loading || cabinet?.loading ? (_jsx(LoadingScreen, {})) : (_jsxs(Card, { children: [_jsxs(Stack, { sx: {
                        paddingTop: '100px',
                        paddingBottom: '100px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '40px',
                        width: '100%',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                    }, children: [_jsx(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '150px',
                                justifyContent: 'center',
                            }, children: _jsxs(Box, { sx: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '60px',
                                    boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
                                    padding: '15px 30px',
                                    borderRadius: '8px',
                                }, children: [_jsx(Avatar, { src: patient?.onData?.avatar, ...stringAvatar(`${patient?.onData?.name}`) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: patient?.onData?.name }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: patient?.onData?.phone }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: patient?.onData?.gender })] })] }) }), _jsxs(Stack, { sx: { gap: '15px' }, children: [_jsx(DesktopDatePicker, { label: 'Date de livraison', maxDate: new Date(`${date.getFullYear() + 10}-01-01`), onChange: (newValue) => {
                                        setStart(newValue);
                                    }, format: "dd/MM/yy" }), _jsx(DesktopDatePicker, { value: end, minDate: start, maxDate: new Date(`${date.getFullYear() + 10}-01-01`), onChange: (newValue) => {
                                        setEnd(newValue);
                                    }, label: 'Date limite', format: "dd/MM/yy" })] })] }), _jsx(Box, { sx: { width: '80%', margin: '0 auto' }, children: !cabinet?.loading && cabinet?.data?.length > 0 && !isDentist && (_jsx(TextField, { label: 'Sélectionnez Votre Cabinet', variant: 'outlined', select: true, fullWidth: true, value: select, sx: { height: '56px' }, SelectProps: {
                            native: true,
                            sx: { textTransform: 'capitalize' },
                        }, onChange: handleChangeCabinet, defaultValue: cabinet?.data[0]?._id, children: cabinet?.data.map((option) => (_jsx("option", { value: option._id, children: option.name }, option._id))) })) }), _jsxs(Box, { sx: {
                        marginTop: '30px',
                        width: '100%',
                        justifyContent: 'end',
                        display: 'flex',
                        padding: '60px',
                        paddingTop: '0px',
                        gap: '15px',
                    }, children: [_jsx(Button, { variant: "outlined", color: "error", onClick: () => {
                                if (treatmentId) {
                                    const value = prothese?.oneData?.traitement?.dents?.map((el) => {
                                        return {
                                            color: el?.color,
                                            name: el?.name,
                                            ombre: el?.ombre,
                                            remarks: el?.remarks,
                                            price: el?.price,
                                            type: el?.type?._id,
                                            material: el?.material?._id,
                                            confirmed: true,
                                        };
                                    });
                                    if (value)
                                        setSelectedDent(Number(value[0]?.name) || 1);
                                    if (value)
                                        dispatch(setDents(value));
                                    dispatch(insertFiles(prothese.oneData?.attachments));
                                    if (id)
                                        navigate(PATH_DASHBOARD.patientForm
                                            .replace(':step', '2')
                                            .replace(':id?', id)
                                            .replace(':treatmentId?', treatmentId) + `?lastStepCompleted=${3}`);
                                    dispatch(setTreatment(prothese?.oneData?.traitement?._id || ''));
                                    dispatch(setUpdate());
                                    dispatch(setDescription(prothese?.oneData?.traitement?.description || ''));
                                    dispatch(resetStep());
                                    dispatch(setProtheseId(treatmentId));
                                    dispatch(setUpdateProthese());
                                    setActiveStep(1);
                                }
                            }, children: "Retourner" }), _jsx(LoadingButton, { variant: "contained", color: "success", loading: loading, onClick: () => {
                                let params = {
                                    status: 'in progress',
                                    patient: patient?.onData?._id,
                                    prothese: prothese?.oneData?._id,
                                };
                                if (start) {
                                    params = { ...params, startDate: new Date(formatDate(start)) };
                                }
                                if (end) {
                                    params = { ...params, endDate: new Date(formatDate(end)) };
                                }
                                setLoading(true);
                                dispatch(createCartePatient({ params })).then((res) => {
                                    if (res?.payload?.statusCode === 200) {
                                        let params = {
                                            cartePatient: res?.payload?.data?._id || '',
                                            cabinet: isDentist ? user?.cabinet?._id : select || '',
                                        };
                                        if (start) {
                                            params = { ...params, startDate: new Date(formatDate(start)) };
                                        }
                                        if (end) {
                                            params = { ...params, endDate: new Date(formatDate(end)) };
                                        }
                                        dispatch(createCommand({ params })).then((res) => {
                                            setLoading(false);
                                            if (res?.payload?.statusCode === 200) {
                                                enqueueSnackbar(`Tout est fait dans l'ordre, votre commande est créée avec succès`);
                                                navigate(PATH_DASHBOARD.patient);
                                            }
                                            else {
                                                enqueueSnackbar(res?.payload?.message || "Quelque chose c'est mal passé !", {
                                                    variant: 'error',
                                                });
                                                setLoading(false);
                                            }
                                        });
                                    }
                                    else {
                                        setLoading(false);
                                        enqueueSnackbar(res?.payload?.message || "Quelque chose c'est mal passé !", {
                                            variant: 'error',
                                        });
                                    }
                                });
                            }, children: "Confirmer" })] })] })) }));
}
